<template>
  <div id="result" v-track="{ event: 'browse', route: $route, isButton: false }">

    <div class="result-body">
      <div style="margin-bottom: 10px;">
        <el-breadcrumb separator="/" v-if="routeName==='taskRecord'">
          <el-breadcrumb-item :to="{ path: '/Empowerment/pledge/pledgeManage/taskRecord' }"><span
              style="color: #BFBFBF;">估价记录</span> </el-breadcrumb-item>
          <el-breadcrumb-item> <span style="color: #018BFF;"> 估价结果 </span></el-breadcrumb-item>
        </el-breadcrumb>
        <el-breadcrumb separator="/" v-if="routeName==='taskDetail'">
          <el-breadcrumb-item :to="{ path: '/Empowerment/pledge/pledgeManage/taskManageNew' }"><span
              style="color: #BFBFBF;">任务管理</span> </el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/Empowerment/pledge/pledgeManage/taskDetail',query:{id,serialNumber,taskName,bitType} }"><span
              style="color: #BFBFBF;">任务详情</span> </el-breadcrumb-item>
          <el-breadcrumb-item> <span style="color: #018BFF;"> 估价结果 </span></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="main-content">
        <!-- 估价结果 -->
        <div class="result">
          <Row :gutter="16" index="">
            <Col span="12">
            <div class="top">
              <div class="title">{{ assessmentDetail.evlTitle }}</div>
              <div class="msg">
                <div class="address">{{ assessmentDetail.lpName }} </div>
                <div class="time">提交时间：{{ assessmentDetail.createTime | filter_assessTime }}</div>
              </div>
            </div>
            <div class="center-box">
              <div class="flex-box">
                <div class="left-box" v-if="(assessmentDetail.assessResult == 1)">
                  <PriceBox :price="assessmentDetail.evaluateTotalPrice.toFixed(2)" unit="总价(万元）"></PriceBox>
                  <PriceBox :price="assessmentDetail.evaluatePrice.toFixed(0)" unit="单价(元/㎡）" :isBlue="false">
                  </PriceBox>
                </div>
                <div class="left-box" v-if="(assessmentDetail.assessResult == 3)">
                  <PriceBox :price="assessmentDetail.evaluateTotalPrice.toFixed(2)" :isYellow="true" unit="参考总价(万元）">
                  </PriceBox>
                  <PriceBox :price="assessmentDetail.evaluatePrice.toFixed(0)" :isYellow="true" unit="参考单价(元/㎡）"
                    :isBlue="false">
                  </PriceBox>
                </div>
                <div class="no-price" v-if="(assessmentDetail.assessResult == 2)">暂无法估价</div>
                <div class="no-price" v-if="(assessmentDetail.assessResult == 0)">
                  <img src="./../../assets/image/pledge/loding.gif" style="margin-top: 46px;" width="70" height="70"
                    alt="" srcset="">
                  <div style="margin-top: -14px;"> 正估价中</div>
                  <div class="tips">30分钟内出值，出值后短信通知</div>
                </div>
                <div class="right-box"  v-if="(assessmentDetail.assessResult == 1 || assessmentDetail.assessResult == 3)">
                  <Button type="primary"
                    v-if="(assessmentDetail.assessResult == 1 || assessmentDetail.assessResult == 3)"
                    @click="openPdfModal"
                     v-track="{ event: 'click', route: $route, isButton: true, log: '下载报告' }"
                    style="width: 100px;">下载结果</Button>
                </div>
              </div>
              <!-- <div class="loan-box" v-if="(assessmentDetail.assessResult == 1 || assessmentDetail.assessResult == 3)">
                <span class="text">贷款额度</span>
                <Select v-model="loanNum" style="width:96px">
                  <Option v-for="item in loanList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
                <div> <span class="price">{{ loanPrice }}</span> <span class="unit">万元</span></div>
              </div> -->
              <div style=" color: #737373; margin-top: 58px; font-size: 16px;">
                <div>面积 <span class="units" v-if="assessmentDetail.buildingArea">{{
                  assessmentDetail.buildingArea
                }}㎡</span>
                </div>
                <div style="margin: 32px 0;">估价基准日：{{ assessmentDetail.createTime | filter_assessTime }}</div>
              </div>

              <div class="announcement"
                v-if="(assessmentDetail.evaluateRemark && (assessmentDetail.assessResult == 1 || assessmentDetail.assessResult == 3))">
                <img src="../../assets/image/pledge/announcement.png" alt="">
                <span> {{ assessmentDetail.evaluateRemark }}</span>
              </div>
              <div class="announcement" v-if="(assessmentDetail.remark && assessmentDetail.assessResult == 2)">
                <img src="../../assets/image/pledge/announcement.png" alt="">
                <span> {{ assessmentDetail.remark }}</span>
              </div>
            </div>
            <div class="detail" v-if="false">
              <div class="item">
                <div class="box">
                  <div class="msg" style="width: 54px;">面积</div>
                  <span class="units" v-if="assessmentDetail.buildingArea">{{ assessmentDetail.buildingArea }}㎡</span>
                  <span class="units" v-else>--</span>
                </div>
                <div class="box">
                  <div class="msg">
                    <Tooltip style="position: absolute;left: 186px" content="指楼栋建成日期" placement="top">
                      <Icon size="20" style="" type="ios-help-circle-outline" />
                    </Tooltip>
                    建成年代
                  </div>
                  <span class="units" v-if="assessmentDetail.completionYear">{{
                    assessmentDetail.completionYear
                  }}</span>
                  <span class="units" v-else>--</span>
                </div>
                <div class="box">
                  <div class="msg">物业类型</div>
                  <span class="units" v-if="assessmentDetail.propertyType">{{ assessmentDetail.propertyType }}</span>
                  <span class="units" v-else>--</span>

                </div>
                <div class="box">
                  <div class="msg" style="width: 36px;">楼层</div>
                  <span class="units" v-if="assessmentDetail.floors">{{ assessmentDetail.floors }}层</span>
                  <span class="units" v-else>--</span>
                </div>
              </div>
              <div class="item">
                <div class="box">
                  <div class="msg" style="width: 54px;">总楼层</div>
                  <span class="units" v-if="assessmentDetail.totalFloors">{{ assessmentDetail.totalFloors }}层</span>
                  <span class="units" v-else>--</span>
                </div>
                <div class="box">
                  <div class="msg">户型</div>
                  <span class="units">{{ houseStyle }}</span>
                </div>
                <div class="box">
                  <div class="msg">有无电梯</div>
                  <span class="units">{{ HaveLiftStyle }}</span>
                </div>
                <div class="box">
                  <div class="msg" style="width: 36px;">朝向</div>
                  <span class="units" v-if="assessmentDetail.orientation">{{ assessmentDetail.orientation }}</span>
                  <span class="units" v-else>--</span>

                </div>
              </div>
            </div>

            </Col>
            <Col span="12">
            <div class="Bmap" id="Bmap"></div>

            </Col>
          </Row>
        </div>
        <!-- 税费计算 -->
        <!-- <div class="taxes"
          v-if="isGuangZhou && (assessmentDetail.assessResult == 1 || assessmentDetail.assessResult == 3)">
          <div>
            <span class="switch-title">税费计算</span>
            <el-switch v-model="taxesSwitch" active-color="#0077FF"
              v-track="{ event: 'click', route: $route, isButton: false, log: '税费测算' }">
            </el-switch>
          </div>
          <div v-show="taxesSwitch">
            <div style="margin-top: 24px; margin-left: -24px;">
              <Form :inline="true" :model="formSwitch" label-position="right" :label-width="100"
                class="demo-form-inline">
                <FormItem label="原购价" label-position="left" :label-width="80">
                  <el-input v-model="formSwitch.originalPurchaseMoney" number :maxlength="10"
                    onkeyup="value=value.replace(/^0+(\d)|[^\d]+/g,'')" size="small" style="width: 140px;"
                    placeholder="请输入"></el-input>
                </FormItem>
                <FormItem label="物业类型">
                  <el-select v-model="formSwitch.officialPropertyType" @change="officialPropertyTypeChange" size="small"
                    style="width: 148px;" placeholder="请选择">
                    <el-option label="普通住宅" value="0"></el-option>
                    <el-option label="非普通住宅" value="1"></el-option>
                  </el-select>
                </FormItem>
                <FormItem label="产权类型">
                  <el-select v-model="formSwitch.personalPropertyType" size="small" style="width: 148px;"
                    placeholder="请选择">
                    <el-option label="个人" :value="true"></el-option>
                  </el-select>
                </FormItem>
                <FormItem label="是否满两年" :label-width="120">
                  <el-select v-model="formSwitch.isTwoYear" @change="isTwoYearChange" size="small" style="width: 148px;"
                    placeholder="请选择">
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </FormItem>
                <FormItem label="满五唯一住房" :label-width="120">
                  <el-select v-model="formSwitch.isFiveOnlyHousing" @change="isFiveOnlyHousingChange" size="small"
                    style="width: 148px;" placeholder="请选择">
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </FormItem>
                <FormItem :label-width="30">
                  <el-button type="primary" @click="taxCalSubmit"
                    v-track="{ event: 'click', route: $route, isButton: true, log: '税费测算-计算' }" size="small"
                    style="width: 100px; background-color: #0077FF;">计算</el-button>
                  <el-popover placement="right" width="550" trigger="hover">
                    <Table :columns="taxesColumns" :data="taxesData"></Table>
                    <Icon slot="reference" size="16" style="margin-left: 16px;" type="ios-help-circle-outline" />
                  </el-popover>
                </FormItem>
              </Form>


            </div>
            <div class="taxesResult">
              <div class="result-left">
                <div class="one-item">
                  <div>抵押净值</div>
                  <div class="taxe-resu-price"> <span style="font-size: 24px;">{{ taxResultObj.equity }}</span> 元</div>
                </div>
              </div>
              <div class="result-right">

                <div class="one-item" style="width: 274px;">
                  <div>税费总额</div>
                  <div class="taxe-resu-price"> <span style="font-size: 24px;">{{ taxResultObj.totalTax }}</span> 元
                  </div>
                </div>

                <div class="right-flex">
                  <div class="right-flex-item">
                    <div>增值税</div>
                    <div class="right-flex-item-price">{{ taxResultObj.taxType1 }}元</div>
                  </div>
                  <div class="right-flex-item">
                    <div>附加税</div>
                    <div class="right-flex-item-price">{{ taxResultObj.taxType2 }}元</div>
                  </div>
                  <div class="right-flex-item">
                    <div>个人所得税</div>
                    <div class="right-flex-item-price">{{ taxResultObj.taxType0 }}元</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- 参考示例 -->
        <div class="example" v-if="!(assessmentDetail.assessResult == 0)"  v-loading="exampleListLoading" element-loading-text="正加载中">
          <div class="ex-title" style="margin-bottom: 12px;"> 参考案例</div>
          <el-tabs v-model="activeExample"   @tab-click="handleClick">
            <el-tab-pane label="成交实例" name="0">
              <Table :columns="exampleColumnList" v-if="exampleList.length > 0"  style="margin-top: 10px;"
                :data="exampleList"></Table>
              <div class="no-data"  v-if="!exampleListLoading&&exampleList.length == 0">
                <img :src="noDataImg"  style="margin-top: -100px;" alt="">
                <div>暂无数据</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="挂牌实例" name="1">
              <Table :columns="exampleColumnListGuapai" style="margin-top: 10px;" v-if="exampleListTow.length > 0"
                :data="exampleListTow"></Table>
              <div class="no-data" v-else>
                <img :src="noDataImg" style="margin-top: -100px;" alt="">
                <div>暂无数据</div>
              </div>
            </el-tab-pane>

          </el-tabs>
        </div>
        <!-- 周边配套 -->
        <div class="surround" v-if="showSurround">
          <div class="ex-title">周边配套</div>

          <NearBy :center='mapData.center' v-if="isShowNearBy"></NearBy>
        </div>
        <!-- 估价建议 -->
        <div v-if="(assessmentDetail.assessResult == 1 || assessmentDetail.assessResult == 3)">
          <div class="suggestion" v-if="isShowFeedbackBlock">
            <div class="ex-title">您对估价结果的建议</div>
            <div class="bit-box">
              <Button type="primary" class="bit" @click="feedBack(-1)">偏低</Button>
              <Button type="primary" class="bit" @click="feedBack(0)">合理</Button>
              <Button type="primary" class="bit" @click="feedBack(1)">偏高</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PdfModal :pdfModel="showpdfModel" :showSurround="showSurround" :exampleColumns="exampleColumnList"
      :exampleColumnsGuapai="exampleColumnListGuapai" :assessmentDetail="assessmentDetail" :exampleList="exampleList"
      :exampleListTow="exampleListTow" :center="mapData.center"></PdfModal>
    <Feedback :feedbackModel="showFeedbackModal" :feedbackObj="assessmentDetail" @feedbackOk="feedbackOk"
      :buildingArea="Number(assessmentDetail.buildingArea)" ref="feedbackModal" :feedbackState="feedbackState">
    </Feedback>
  </div>
</template>
<script>
import PriceBox from '@/views/pledge/components/PriceBox.vue'
import PdfModal from '@/views/pledge/components/taskPdfModal.vue'
import Feedback from '@/views/pledge/components/feedback.vue'
import NearBy from '@/views/pledge/components/nearBy/index.vue'
import { taxCalculator, resultHistory, exampleList, addDataUserFeedback,getEvlReport } from '@/api/pledge'
import dayjs from 'dayjs'
import CityList from './cityList.js'

import {
  data_user_operation_log_in
} from '@/api/operationLog.js'
import {
  formatTime,


} from "@/utils";
import dataReports from "@/mixins/dataReports";

export default {
  mixins: [dataReports],

  components: {
    PriceBox,
    NearBy,
    PdfModal,
    Feedback
  },

  data() {
    return {
    routeName: this.$route.query.routeName,
    id: this.$route.query.id,
    serialNumber: this.$route.query.serialNumber,
    taskName: this.$route.query.taskName,
    bitType: this.$route.query.bitType,
      isOpenPdfModal: false,//是否能点击下载报告
      showSurround: true,//周边配套显示
      isShowNearBy: false,
      showFeedbackBlock: true,
      sampleHistoryId: null,//这是历史id，查询入参
      assessmentDetail: {//估价结果相关
        // "assessResult": 1,
        // "assessTime": 1669877328000,
        // "balconyCnt": 2,
        // "buildingArea": 137.83,
        // "certAddress": "广东省广州市天河区科新路18号之一301",
        // "cityCode": "440100",
        // "completionYear": "2007",
        // "countyCode": "440106000",
        // "countyFullName": "天河区",
        // "evaluateBasicDate": "2022-11-01",
        // "evaluatePrice": 52290.98,
        // "evaluateRemark": "该价格基于市场情况计算，该价格基于市场情况计算该价格基于市场情况计算该价格基于市场",
        // "evaluateTotalPrice": 522.91,
        // "evlTitle": "广州市天河区",
        // "floors": 3,
        // "houseCode": "61a617f7-5f17-11ed-ac16-000c29433a6f",
        // "isHaveLift": true,
        // "lpCode": "c39b3b81-5f16-11ed-ac16-000c29433a6f",
        // "lpLatBaidu": 23.12842,
        // "lpLngBaidu": 113.391594,
        // "lpName": "骏景花园",
        // "orientation": "",
        // "parlourCnt": 2,
        // "propertyType": "住宅",
        // "propertyTypeAll": "住宅",
        // "provinceCode": "44",
        // "roomCnt": 4,
        // "roomNum": "301",
        // "toiletCnt": 2,
        // "totalFloors": 24,
        // "userId": 610

      },
      feedbackState: 0,
      showpdfModel: false,//pdf 下载弹框
      showFeedbackModal: false,//建议，弹框
      cityObj: JSON.parse(sessionStorage.getItem('cityObj')),
      cityList: CityList.cityList,
      activeExample: '0',//参考实例 选项卡
      exampleColumns: [//参考实例，表格表头
        {
          title: '小区名称',
          key: 'lpName',
          width: '420px',
          render: (h, params) => {
            let texts = params.row.lpName
            if (params.row.lpName != null) {
              if (params.row.lpName.length > 20) {
                texts = params.row.lpName.substring(0, 20) + '...' // 进行数字截取或slice截取超过长度时以...表示
              } else {
                texts = params.row.lpName
              }
            }
            let sameLpText = ''
            let samelpColor = '#FF8000'
            let samelpBgColor = '#FFECD9'
            if (params.row.isSameLp) {
              sameLpText = '同小区',
                samelpColor = '#FF8000',
                samelpBgColor = '#FFECD9'
            } else {
              sameLpText = '周边小区',
                samelpColor = '#0077FF',
                samelpBgColor = '#DDEBFE'

            }
            return h(
              "div",

              {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                }
              },

              [
                h(
                  "div",
                  {
                    style: {
                      background: samelpBgColor,
                      color: samelpColor,
                      width: ' 80px',
                      height: '30px',
                      marginRight: '10px',
                      textAlign: 'center',
                      lineHeight: '30px',
                      borderRadius: '4px',
                    }
                  },
                  sameLpText,
                ),
                h(
                  "span",
                  texts,
                ),
              ]
            );
          },
        },
        {
          title: '面积',
          key: 'buildingAreaCase',
          render: (h, params) => {
            return h(
              "span",
              params.row.buildingAreaCase + '㎡'
            );
          },
        },
        {
          title: '房屋户型',
          key: 'houseType'
        },
        {
          title: '单价',
          key: 'dealUnitPriceCase',
          render: (h, params) => {
            return h(
              "span",
              params.row.dealUnitPriceCase + '元/㎡'
            );
          },
        },
        {
          title: '总价',
          key: 'dealTotalPriceCase',
          render: (h, params) => {
            return h(
              "span",
              params.row.dealTotalPriceCase + '万元'
            );
          },
        },
        {
          title: '成交时间',
          key: 'dealDate'
        },
      ],
      exampleColumnsGuapai: [//参考实例，表格表头
        {
          title: '小区名称',
          key: 'lpName',
          width: '420px',
          render: (h, params) => {
            let texts = params.row.lpName
            if (params.row.lpName != null) {
              if (params.row.lpName.length > 20) {
                texts = params.row.lpName.substring(0, 20) + '...' // 进行数字截取或slice截取超过长度时以...表示
              } else {
                texts = params.row.lpName
              }
            }
            let sameLpText = ''
            let samelpColor = '#FF8000'
            let samelpBgColor = '#FFECD9'
            if (params.row.isSameLp) {
              sameLpText = '同小区',
                samelpColor = '#FF8000',
                samelpBgColor = '#FFECD9'
            } else {
              sameLpText = '周边小区',
                samelpColor = '#0077FF',
                samelpBgColor = '#DDEBFE'

            }
            return h(
              "div",

              {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                }
              },

              [
                h(
                  "div",
                  {
                    style: {
                      background: samelpBgColor,
                      color: samelpColor,
                      width: ' 80px',
                      height: '30px',
                      marginRight: '10px',
                      textAlign: 'center',
                      lineHeight: '30px',
                      borderRadius: '4px',
                    }
                  },
                  sameLpText,
                ),
                h(
                  "span",
                  texts,
                ),
              ]
            );
          },
        },
        {
          title: '面积',
          key: 'buildingAreaCase',
          render: (h, params) => {
            return h(
              "span",
              params.row.buildingAreaCase + '㎡'
            );
          },
        },
        {
          title: '房屋户型',
          key: 'houseType'
        },
        {
          title: '单价',
          key: 'dealUnitPriceCase',
          render: (h, params) => {
            return h(
              "span",
              params.row.dealUnitPriceCase + '元/㎡'
            );
          },
        },
        {
          title: '总价',
          key: 'dealTotalPriceCase',
          render: (h, params) => {
            return h(
              "span",
              params.row.dealTotalPriceCase + '万元'
            );
          },
        },
        {
          title: '挂牌时间',
          key: 'dealDate'
        },
      ],
      exampleColumnsTow: [
        {
          title: '面积',
          key: 'buildingAreaCase',
          render: (h, params) => {
            return h(
              "span",
              params.row.buildingAreaCase + '㎡'
            );
          },
        },
        {
          title: '房屋户型',
          key: 'houseType'
        },
        {
          title: '单价',
          key: 'dealUnitPriceCase',
          render: (h, params) => {
            return h(
              "span",
              params.row.dealUnitPriceCase + '元/㎡'
            );
          },
        },
        {
          title: '总价',
          key: 'dealTotalPriceCase',
          render: (h, params) => {
            return h(
              "span",
              params.row.dealTotalPriceCase + '万元'
            );
          },
        },
        {
          title: '挂牌时间',
          key: 'dealDate'
        },
      ],
      exampleColumnsTowGuapai: [
        {
          title: '面积',
          key: 'buildingAreaCase',
          render: (h, params) => {
            return h(
              "span",
              params.row.buildingAreaCase + '㎡'
            );
          },
        },
        {
          title: '房屋户型',
          key: 'houseType'
        },
        {
          title: '单价',
          key: 'dealUnitPriceCase',
          render: (h, params) => {
            return h(
              "span",
              params.row.dealUnitPriceCase + '元/㎡'
            );
          },
        },
        {
          title: '总价',
          key: 'dealTotalPriceCase',
          render: (h, params) => {
            return h(
              "span",
              params.row.dealTotalPriceCase + '万元'
            );
          },
        },
        {
          title: '挂牌时间',
          key: 'dealDate'
        },
      ],
      exampleList: [//成交案例data

      ],
      exampleListLoading: true,//成交案例Loading
      // noDataImg: require("@/assets/image/pledge/no_data.png"),
       noDataImg: require("@/assets/image/task/empty.png"),
      exampleListTow: [//挂牌实例

      ],
      taxesColumns: [
        {
          title: '税种',
          key: 'tax',
          width: '150px'
        },
        {
          title: '计算公式',
          key: 'formula',
          // width: '200px'
        },
        {
          title: '税率',
          key: 'rate'
        }
      ],
      taxesData: [
        {
          tax: '增值税',
          formula: '0',
          rate: '5%'
        },
        {
          tax: '附加税',
          formula: '增值税*税率',
          rate: '12%'
        },
        {
          tax: '个人所得税',
          formula: ' (评估价-增值率)*税率',
          rate: '1.00%'
        },
      ],
      taxesSwitch: true,//税费计算-开关
      formSwitch: {//税费计算-表单提交
        originalPurchaseMoney: '',//原购价
        officialPropertyType: '0',//物业类型
        personalPropertyType: '',//产权类型
        isTwoYear: true,//是否满两年
        isFiveOnlyHousing: true,//满五唯一住房


      },
      taxResultObj: {//税费计算-结果
        equity: '0.00',
        totalTax: "0.00",
        taxType0: "0.00",
        taxType1: "0.00",
        taxType2: "0.00",
      },
      loanNum: 0.7,//贷款额度
      loanList: [
        {
          value: 0.1,
          label: '1成',

        },
        {
          value: 0.2,
          label: '2成'
        },
        {
          value: 0.3,
          label: '3成'
        },
        {
          value: 0.4,
          label: '4成'
        },
        {
          value: 0.5,
          label: '5成'
        },
        {
          value: 0.6,
          label: '6成'
        },
        {
          value: 0.7,
          label: '7成'
        },
        {
          value: 0.8,
          label: '8成'
        },
        {
          value: 0.9,
          label: '9成'
        },
      ],
      foShanCenter: {//佛山市政府经纬度
        lng: 113.128212,
        lat: 23.027216
      },
      dongGuanCenter: {//东莞市政府经纬度
        lng: 113.758184,
        lat: 23.027314
      },
      guangzhouCenter: {//广州市 中和广场经纬度
        lng: 113.335593,
        lat: 23.119767,
      },
      liveIcon: require("@/assets/image/map/localNew.png"),
      //地图信息
      mapData: {
        zoom: 17,
        center: {
          lng: 113.335593,
          lat: 23.119767,
        },
      },
    };
  },
  watch: {
    activeExample(val) {
      // if (val==0) {
      //   this.exampleColumns[ this.exampleColumns.length-1].title = '成交时间'
      //   this.exampleColumnsTow[ this.exampleColumnsTow.length-1].title = '成交时间'
      // }else{
      //   this.exampleColumns[ this.exampleColumns.length-1].title = '挂牌时间'
      //   this.exampleColumnsTow[ this.exampleColumnsTow.length-1].title = '挂牌时间'

      // }
    },
  },
  filters: {
    filter_assessTime(val) {
      return dayjs(val).format('YYYY-MM-DD');
    }
  },
  created() {
    this.getResult()
  },
  mounted() {
    this.taxesDataInit()
  },
  computed: {
    isGuangZhou() {//判断是否为广州
      let { cityCode } = this.cityObj
      if (cityCode == this.cityList[0].cityCode) {
        return true
      } else {
        return false
      }
    },
    isShowFeedbackBlock() {
      let feedbackStates = this.assessmentDetail.feedbackStates
      let arr = [-1, 0, 1]
      let isIncludes = arr.includes(feedbackStates)
      return this.showFeedbackBlock && !isIncludes
    },
    loanPrice() { //贷款价钱
      return (this.loanNum * this.assessmentDetail.evaluateTotalPrice).toFixed(2)
    },
    exampleColumnList() {//参考实例动态表格
    return this.exampleColumns
      // return this.isGuangZhou ? this.exampleColumns : this.exampleColumnsTow
    },
    exampleColumnListGuapai() {//参考实例动态表格-挂牌
    return this.exampleColumnsGuapai
      // return this.isGuangZhou ? this.exampleColumnsGuapai : this.exampleColumnsTowGuapai
    },
    houseStyle() {
      let roomCnt = this.assessmentDetail.roomCnt ? this.assessmentDetail.roomCnt : 0
      let parlourCnt = this.assessmentDetail.parlourCnt ? this.assessmentDetail.parlourCnt : 0
      if (roomCnt == 0) return '--'
       if (parlourCnt == 0) return `${roomCnt}房`
      return `${roomCnt}房${parlourCnt}厅`
    },

    HaveLiftStyle() {//电梯
      let isHaveLift = this.assessmentDetail.isHaveLift
      if (isHaveLift === '') return '--'
      if (isHaveLift) {
        return '有'
      } else {
        return '无'
      }
    },
  },
  methods: {
    isTwoYearChange(val) {//是否满两年

      if (!val) {
        this.formSwitch.isFiveOnlyHousing = false
      }
      this.taxesDataInit()
    },
    isFiveOnlyHousingChange(val) {//满五唯一住房
      if (val) {
        this.formSwitch.isTwoYear = true

      }
      // this.taxesDataInit()

    },
    officialPropertyTypeChange(val) {//物业类型
      this.taxesDataInit()

    },

    taxesDataInit() {//税费计算-初始化
      let formSwitch = this.formSwitch
      if (formSwitch.isTwoYear && formSwitch.officialPropertyType == 0) {//满两年且为普通住宅
        this.taxesData[0].formula = '0'
      }
      if (formSwitch.isTwoYear && formSwitch.officialPropertyType == 1) {//满两年且为非普通住宅
        this.taxesData[0].formula = '增值税=（评估总价-原购价）/1.05*5%'

      }
      if (!formSwitch.isTwoYear) {//不满两年
        this.taxesData[0].formula = '增值税=（评估总价-原购价）/1.05*5%'

      }

      if (formSwitch.isFiveOnlyHousing) {//满五唯一住房
        this.taxesData[3].formula = '0'
        this.taxesData[3].rate = '0'

      }
      if (!formSwitch.isFiveOnlyHousing && formSwitch.officialPropertyType == 0) {//不满五唯一住房且为普通住宅
        this.taxesData[3].formula = '（评估总价-增值税）*1%'
        this.taxesData[3].rate = '1%'

      }
      if (!formSwitch.isFiveOnlyHousing && formSwitch.officialPropertyType == 1) {//不满五唯一住房且为非普通住宅
        this.taxesData[3].formula = '（评估总价-增值税）*1.5%'
        this.taxesData[3].rate = '1.5%'

      }


    },
    //去估价页
    again() {
      this.$router.push({ path: '/Empowerment/pledge/houseProperty/rapidValuation' })
    },

    //获取估价结果
    async getResult() {
      let { cityCode, sampleHistoryId } = this.$route.query
      this.sampleHistoryId = sampleHistoryId
      this.cityList.map(item => {
        if (cityCode == item.cityCode) {
          this.cityObj = item

        }
      })
      let params = {
        cityCode,
        sampleHistoryId
      }
      let res = await resultHistory(params)
      this.assessmentDetail = res
      if (res.buildingArea > 144) {
        this.formSwitch.officialPropertyType = '1'

      }
      if (res.assessResult == 1 || res.assessResult == 3 || res.assessResult == 2) {
        this.getExampleList()

      }else{
      this.exampleListLoading = false
      }
      if (res.lpLatBaidu) {
        this.mapData.center.lat = res.lpLatBaidu
        this.mapData.center.lng = res.lpLngBaidu
        this.loadMap(BMap)

      } else {
        this.showSurround = false
        this.getCenterLocation()
      }
      this.isShowNearBy = true
    },
    getCenterLocation() {
      let { cityCode } = this.cityObj
      if (cityCode == this.cityList[1].cityCode) {// 佛山
        this.mapData.center = this.foShanCenter
      } else if (cityCode == this.cityList[2].cityCode) {//东莞
        this.mapData.center = this.dongGuanCenter
      } else {
        this.mapData.center = this.guangzhouCenter
      }

      this.loadMap(BMap)
    },

    getExampleList() {//获取参考案例
      let assessmentDetail = this.assessmentDetail
      delete assessmentDetail.lat
      delete assessmentDetail.lng
      delete assessmentDetail.lpLngTencent
      delete assessmentDetail.lpLatTencent
         let paramsOne = {
      sampleHistoryId:assessmentDetail.sampleHistoryId,
      type: 1//成交
      }
      let paramsTow = {
      sampleHistoryId:assessmentDetail.sampleHistoryId,
      type: 0//挂牌
      }

      Promise.all([exampleList(paramsOne), exampleList(paramsTow)]).then(res => {
        this.exampleListLoading = false
        this.isOpenPdfModal = true
        let cjData = res[0]
        this.exampleList = cjData || []
        let cjDataTow = res[1]
        this.exampleListTow = cjDataTow || []
        if (this.exampleList.length == 0 && this.exampleListTow.length > 0) { //当成交价没有而挂牌价有时
          this.activeExample = '1'
        }

      }).catch(err => {
        this.exampleListLoading = false
        this.isOpenPdfModal = true
      })


    },


    //反馈
    feedBack(e) {
      this.feedbackState = `${e}`
      let that = this
      let logText = ''

      if (e === -1) {
        logText = '偏低'
      } else if (e === 0) {
        logText = '合理'

      } else if (e === 1) {
        logText = '偏高'
      }
      let _params = {
        event: 'click',
        isButton: true,
        log: logText,
        os: navigator.userAgent,
        platform: '数据平台PC',
        route: that.$route.path,
        title: that.$route.meta.title,
        token: that.$store.state.token ? that.$store.state
          .token : '',
        user_id: that.$store.state.userId ? that.$store
          .state
          .userId : '',
      }
      data_user_operation_log_in(_params)
      if (e == 0) {
        this.addDataUserFeedbacks(e)
        // this.$refs.feedbackModal.ok(0)
        this.showFeedbackBlock = false
      } else {
        this.showFeedbackModal = true
      }
    },
    addDataUserFeedbacks(e) {
      let params = {
        cityCode: this.assessmentDetail.cityCode,
        assessBaseDate: this.assessmentDetail.evaluateBasicDate,
        feedbackReason: '',
        feedbackStates: e,
        feedbackTotalPrice: (Number(this.assessmentDetail.evaluatePrice) * 1).toFixed(0),
        feedbackUnitPrice: (Number(this.assessmentDetail.evaluateTotalPrice) * 1).toFixed(2),
        sampleHistoryId: this.assessmentDetail.sampleHistoryId,
        certificateMd5: this.assessmentDetail.certificateMd5,
        platform: '1'
      }
      addDataUserFeedback(params).then(res => {
        this.$msg.success({ text: "谢谢！" });
      })

    },
    feedbackOk() {
      this.showFeedbackBlock = false
    },
    switchChange(status) {//税费计算开关
    },
    async taxCalSubmit() {//税费计算-计算按钮

      if (this.formSwitch.officialPropertyType == 1 && this.formSwitch.isTwoYear && !this.formSwitch.originalPurchaseMoney) {
        this.$msg.error({ text: "请先输入原购价" })
        return
      }

      let params = Object.assign({}, this.formSwitch)
      params.origin = 1
      params.cityCode = this.cityObj.cityCode
      params.sampleHistoryId = this.sampleHistoryId//
      let res = await taxCalculator(params)

      this.taxResultObj = res
    },
   async openPdfModal() {//下载报告-按钮
          let params = {
        historyId: this.sampleHistoryId
      }
      let res = await getEvlReport(params)
      if (res !=='估价报告成功下载') {
        return
      }
      if (!this.isOpenPdfModal) {
        this.$Message.info('数据正加载中，请稍后')
        return
      }
      this.showpdfModel = true
    },
    loadMap(BMap) {//地图打点
      let that = this;

      that.map = new BMap.Map("Bmap");
      const myPoint = new BMap.Point(
        that.mapData.center.lng,
        that.mapData.center.lat
      );
      // 初始化地图， 设置中心点坐标和地图级别
      that.map.centerAndZoom(myPoint, that.mapData.zoom);
      that.map.enableScrollWheelZoom(true);
      const icon = new BMap.Icon(that.liveIcon, new BMap.Size(34, 58), {
        anchor: new BMap.Size(25, 60)
      });
      icon.setSize(new BMap.Size(34, 58));
      icon.setImageSize(new BMap.Size(34, 58));
      that.map.enableScrollWheelZoom();
      let marker = new BMap.Marker(myPoint, {
        icon,
      });
      marker.addEventListener("click", () => {
        that.map.centerAndZoom(myPoint, 17);
      });
      that.map.addOverlay(marker);

    },
  }
};
</script>

<style scoped lang="scss">
.result-body {
  background: #EFF1F6;
  // height: 100%;
  // min-height: 100%;
  min-height: 100vh;
  padding: 24px;
  min-width: 1600px;

  .main-content {
    // height: 100%;
    min-width: 1600px;
    border-radius: 10px 10px 10px 10px;

    //估价结果
    .result {
      padding: 24px;
      width: 100%;
      height: 617px;
      background-color: #fff;
      border-radius: 10px 10px 10px 10px;

      .top {
        .title {
          font-size: 20px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #404040;
          margin-bottom: 22px;
        }

        .msg {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #737373;


          .address {
            color: #737373;
            ;

          }
        }

      }

      .center-box {
        padding-left: 24px;
        padding-right: 36px;
        width: 100%;
        margin-top: 18px;
        height: 475px;
        background: #F7F8FA;
        border-radius: 4px 4px 4px 4px;
        padding-top: 24px;

        .flex-box {
          display: flex;
          justify-content: space-between;

          .left-box {
            display: flex;
            justify-content: space-between;
            width: 538px;
          }

          .no-price {
            // width: 520px;
            width: 100%;
            font-family: PingFang SC-Bold, PingFang SC;
            text-align: center;
            font-weight: 550;
            font-size: 56px;
            font-family: PingFang SC-Bold, PingFang SC;
            color: #737373;

            .tips {
              font-family: PingFang SC Regular;
              font-size: 16px;
              font-weight: 300;
              line-height: 20px;
              text-align: center;
              letter-spacing: 0em;

              color: #999999;
            }
          }

          .right-box {
            display: flex;
            flex-direction: column-reverse;
            // justify-content: center;
            padding: 0;
            width: 120px;
            justify-content: center;
            .top-bit {
              margin-top: 32px;
            }
          }
        }
      }

      .loan-box {
        display: flex;
        margin-top: 36px;
        align-items: center;

        .text {
          font-size: 16px;
          font-family: PingFangSC-Regular-, PingFangSC-Regular;
          font-weight: normal;
          color: #737373;
          margin-right: 16px;
        }

        .price {
          margin-left: 16px;
          margin-right: 7px;
          font-size: 20px;
          font-family: DINPro-Black, DINPro;
          font-weight: 900;
          color: #404040;
        }

        .unit {

          font-size: 20px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #404040;
        }
      }

      .announcement {
        margin-top: 20px;
        font-size: 16px;
        display: flex;
        // align-items: center;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #737373;

        img {
          width: 17px;
          height: 17px;
          margin-top: 5px;
          margin-right: 5px;
        }
      }

      .detail {
        margin-top: 30px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #737373;

        .item {
          display: flex;
          margin-top: 18px;

          .box {
            margin-right: 48px;
            width: 160px;
            display: flex;

            .msg {
              width: 76px;
              margin-right: 16px;
            }

            .units {
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: bold;
              color: #404040;
              // flex: 1;
              width: 85px;
            }
          }

        }

      }

      #Bmap {
        height: 570px;
        // min-width: 748px;
        border-radius: 10px;
        width: 100%;
        background: #eee;
      }
    }

    //税费计算
    .taxes {
      margin-top: 24px;
      padding: 24px;
      width: 100%;
      padding-bottom: 32px;
      // height: 328px;
      background: #FFFFFF;
      border-radius: 10px 10px 10px 10px;

      .taxesResult {
        display: flex;
        height: 88px;
        margin-top: 8px;

        .result-left {
          width: 320px;
          height: 100%;
          border-radius: 8px 8px 8px 8px;

          background: #F7F8FA;
          margin-right: 26px;


        }

        .one-item {
          color: #737373;
          padding-top: 16px;
          padding-left: 24px;

          .taxe-resu-price {

            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #262626;
          }
        }

        .result-right {

          width: 1063px;
          height: 100%;
          background: #F7F8FA;
          border-radius: 8px 8px 8px 8px;
          display: flex;

          .right-flex {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #737373;
            display: flex;
            align-items: center;

            .right-flex-item {
              padding-top: 7px;
              padding-left: 20px;
              border-radius: 8px 8px 8px 8px;
              width: 220px;
              margin-left: 36px;
              height: 69px;
              background: #F1F2F5;

              .right-flex-item-price {
                color: #262626;
                margin-top: 11px;
              }
            }
          }
        }
      }
    }

    // 参考案例
    .example {
      margin-top: 24px;
      padding: 24px;
      width: 100%;
      height: 346px;
      // height: 328px;
      background: #FFFFFF;
      border-radius: 10px 10px 10px 10px;

      .ex-title {
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #262626;

      }

      .no-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 250px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #A6A6A6;
      }
    }

    // 周边配套
    .surround {
      width: 100%;
      margin-top: 24px;
      padding: 24px;
      height: 908px;
      background: #FFFFFF;
      border-radius: 10px 10px 10px 10px;

      .ex-title {
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #262626;
      }
    }

    // 估价建议
    .suggestion {
      width: 100%;
      margin-top: 24px;
      padding: 24px;
      height: 150px;
      background: #FFFFFF;
      border-radius: 10px 10px 10px 10px;

      .ex-title {
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #262626;
      }

      .bit-box {
        width: 100%;
        display: flex;
        margin-top: 27px;
        justify-content: center;

        .bit {
          margin-right: 48px;
        }
      }
    }

    .switch-title {
      font-size: 18px;
      margin-right: 10px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #262626;
    }
  }
}

/deep/ .el-tabs__item {
  font-size: 16px;
  // color: #515a6e;
}
</style>
